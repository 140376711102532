<template>
  <div>
    <ca-card
      v-if="damageReports && damageReports.length > 0"
      class="mb-3"
    >
      <template #header>
        <div class="row">
          <div class="col">
            <h4 class="mb-0">
              {{ title }}
            </h4>
          </div>
        </div>
      </template>
      <template #default>
        <template v-for="(damageReport, index) in damageReports">
          <div
            :key="index"
            class="row rounded-lg align-items-center cursor-pointer hover-gray-1 px-3 py-2"
            @click="$router.push({name: 'DamageReportDetail', params: {damageReportId: damageReport._id}})"
          >
            <div class="col-auto pr-2">
              <div class="order-icon-wrapper bg-secondary">
                <i class="far fa-bullhorn fa-2x text-primary" />
              </div>
            </div>
            <div class="col">
              <h5 class="mb-0 d-inline">
                {{ damageReport.number }}
              </h5>
              <small class="d-block">
                {{ damageReport.damageDate | formatDate }}
              </small>
            </div>
          </div>
        </template>
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import formatDate from '@/filters/formatDate.js'
import formatOrderStatus from '@/filters/formatOrderStatus.js'
export default {
  components: {
    CaCard
  },
  filters: {
    formatDate,
    formatOrderStatus
  },
  props: {
    damageReports: {
      default: () => [],
      type: Array
    },
    title: {
      default: 'Schäden',
      type: String
    },

    /**
     * Object with user object. can be extended
     * example { car: { ... } }
     */
    params: {
      type: Object,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.order-icon-wrapper{
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 50%;
  $size: 40px;
  width: $size;
  height: $size;
  position: relative;
  i{
    position: absolute;
    right: -5px;
    bottom: -5px;
  }
}

</style>
