<template>
  <div>
    <ca-header heading="Blacklist Fahrgestellnummer Bearbeiten">
      <template #alert>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!saveErrorMessage"
            variant="danger"
            :message="saveErrorMessage"
            @close="saveErrorMessage = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!saveSuccessMessage"
            variant="success"
            :message="saveSuccessMessage"
            @close="saveSuccessMessage = null"
          />
        </transition>
        <transition name="slide-fade">
          <ca-alert
            v-if="showFormInvalidHint"
            variant="info"
            message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
            @close="showFormInvalidHint = null"
          />
        </transition>
      </template>
      <template #buttonSpace>
        <button
          :disabled="savePending"
          class="btn btn-success text-white"
          @click="save()"
        >
          Speichern
        </button>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div class="row">
        <div class="col-12 col-xl-8 col-xxl-6">
          <BlacklistedChassisFormDetailed
            v-if="!loading"
            :blacklisted-chassis="blacklistedChassis"
          />
        </div>
        <div class="col-12 col-xl-4 col-xxl-3">
          <RelatedOffers
            v-if="orders"
            :orders="orders"
            :can-create-order="false"
            title="Verträge mit dieser FIN"
          />
          <RelatedDamageReports
            v-if="damageReports"
            :damage-reports="damageReports"
            title="Schäden mit dieser FIN"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlacklistedChassisFormDetailed from './components/BlacklistedChassisFormDetailed'
import RelatedOffers from '@/components/RelatedOffers'
import RelatedDamageReports from '@/components/RelatedDamageReports'
import CaHeader from '@/components/Layout/Header'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'BlacklistedChassisEdit',
  components: {
    BlacklistedChassisFormDetailed,
    RelatedOffers,
    RelatedDamageReports,
    CaHeader
  },
  data () {
    return {
      loading: false,
      loadingError: null,
      blacklistedChassis: null,
      savePending: false,
      saveError: null,
      saveSuccessMessage: null,
      saveErrorMessage: null,
      showFormInvalidHint: false,
      orders: null,
      damageReports: null
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.blacklistedChassis = await this.$store.dispatch('blacklisted-chassis/get', this.$route.params.blacklistedChassisId)
        this.blacklistedChassis.authorId = this.$store.getters['auth/user'].user._id

        this.orders = (await this.$store.dispatch('orders/find', {
          query: { 'car.chassi': this.blacklistedChassis.chassi }
        })).data

        this.damageReports = (await this.$store.dispatch('damage-reports/find', {
          query: {
            orderId: {
              $in: this.orders
            }
          }
        })).data
      } catch (error) {
        this.loadingError = error
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.userSavePending) {
          return
        }
        if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.savePending = true
        this.saveErrorMessage = null
        this.showFormInvalidHint = false
        await this.blacklistedChassis.save()
        this.savePending = false
        this.saveSuccessMessage = 'Speichern erfolgreich'
      } catch (error) {
        if (error.code === 409) {
          this.saveErrorMessage = 'Fahrgestellnummer existiert bereits.'
        } else {
          this.saveErrorMessage = error.message
        }
        this.saveError = error
        console.error(error)
      } finally {
        this.savePending = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .site-header{
    z-index: 1020;
  }
  .products-enter-active, .products-leave-active {
    transition: all 300ms;
  }
  .products-enter, .products-leave-to {
    opacity: 0;
    transform: translatex(30px);
  }
</style>
